import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "lib-flexible/flexible.js"
import { ElMessage } from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'


//在app中引用
const app = createApp(App);

app.config.globalProperties.$msg = (res: { code: number, msg: string }) => {
    if (res.code < 400) {
        ElMessage.success(res.msg)
    } 
}

app.use(store).use(router).use(ElementPlus, {
    locale: zhCn,
  }).mount('#app')
