import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home/index.vue'),
    redirect: '/home/info',
    children: [
      {
        path: '/home/goods',
        name: 'goods',
        component: () => import('../views/goods/index.vue')
      }, {
        path: '/home/info',
        name: 'info',
        component: () => import('../views/info/index.vue')
      }, {
        path: '/home/homeCarouselImage',
        name: 'homeCarouselImage',
        component: () => import('../views/homeCarouselImage/index.vue')
      }, {
        path: '/home/homeCarouselImage1',
        name: 'homeCarouselImage1',
        component: () => import('../views/homeCarouselImage1/index.vue')
      },
      {
        path: '/home/tabs',
        name: 'tabs',
        component: () => import('../views/tabs/index.vue')
      }, {
        path: '/home/user',
        name: 'user',
        component: () => import('../views/user/index.vue')
      }, {
        path: '/home/otherSettings',
        name: 'otherSettings',
        component: () => import('../views/otherSettings/index.vue')
      }, {
        path: '/home/customerService',
        name: 'customerService',
        component: () => import('../views/customerService/index.vue')
      }, {
        path: '/home/business',
        name: 'business',
        component: () => import('../views/business/index.vue')
      }, {
        path: '/home/blackList',
        name: 'blackList',
        component: () => import('../views/blackList/index.vue')
      }, {
        path: '/home/userManagement',
        name: 'userManagement',
        component: () => import('../views/userManagement/index.vue')
      }, {
        path: '/home/businessApproval',
        name: 'businessApproval',
        component: () => import('../views/businessApproval/index.vue')
      }, {
        path: '/home/secondHand',
        name: 'secondHand',
        component: () => import('../views/secondHand/index.vue')
      }, {
        path: '/home/product',
        name: 'product',
        component: () => import('../views/product/index.vue')
      }, {
        path: '/home/productApproval',
        name: 'productApproval',
        component: () => import('../views/product/approval.vue')
      },
      {
        path: '/home/loveDonation',
        name: 'loveDonation',
        component: () => import('../views/loveDonation/index.vue')
      },
      {
        path: '/home/abduct',
        name: 'abduct',
        component: () => import('../views/abduct/index.vue')
      }, {
        path: '/home/comment',
        name: 'comment',
        component: () => import('../views/comment/index.vue')
      }, {
        path: '/home/relatedWords',
        name: 'relatedWords',
        component: () => import('../views/relatedWords/index.vue')
      }, {
        path: '/home/secondHandApproval',
        name: 'secondHandApproval',
        component: () => import('../views/secondHandApproval/index.vue')
      }, {
        path: '/home/international',
        name: 'international',
        component: () => import('../views/international/index.vue')
      }, {
        path: '/home/order',
        name: 'order',
        component: () => import('../views/order/index.vue')
      }


      

    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {

  if (to.path == '/login' || to.path == '/') {
    next();

  } else {
    if (localStorage.getItem("UserInfo")) {
      next();
    } else {
      alert('您还没有登录，请先登录');
      next('/login');
    }
  }

})


export default router
