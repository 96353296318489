import { createStore } from 'vuex'

let obj:any = null;
if(localStorage.getItem("UserInfo")){
  const a:any = localStorage.getItem("UserInfo")
  obj = JSON.parse(a);
}
export default createStore({
  state: {
    userInfo:obj,
  },
  getters: {
  },
  mutations: {
    setUserInfo(state, payload) {
      state.userInfo = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
